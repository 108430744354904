<template>
  <b-modal :modal-class="{'activeTour' : getGuidedTour.step5}" centered modal-class="delete_brand_model"
           id="modal-archive-link-v2" hide-footer
           hide-header>
    <div class="w-full h-full">
      <div @click="$bvModal.hide('modal-archive-link-v2')" class="float-right w-6 h-6 flex justify-end">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 cursor-pointer" viewBox="0 0 12 12" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8161 2.24461C11.109 1.95172 11.109 1.47684 10.8161 1.18395C10.5232 0.891056 10.0483 0.891056 9.75546 1.18395L6.00007 4.93933L2.24469 1.18395C1.95179 0.891056 1.47692 0.891056 1.18403 1.18395C0.891132 1.47684 0.891132 1.95172 1.18403 2.24461L4.93941 5.99999L1.18403 9.75538C0.891132 10.0483 0.891132 10.5231 1.18403 10.816C1.47692 11.1089 1.95179 11.1089 2.24469 10.816L6.00007 7.06065L9.75546 10.816C10.0483 11.1089 10.5232 11.1089 10.8161 10.816C11.109 10.5231 11.109 10.0483 10.8161 9.75538L7.06073 5.99999L10.8161 2.24461Z" fill="black"/>
        </svg>
      </div>
      <div class="w-full px-10 flex flex-col items-center pt-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="27" viewBox="0 0 32 27" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M1.02126 0.000108004C0.457223 0.000108004 -1.84774e-05 0.45735 -1.84774e-05 1.02139V7.82991C-1.84774e-05 8.39394 0.457223 8.85118 1.02126 8.85118H2.7233V25.5286C2.7233 26.0927 3.18054 26.5499 3.74458 26.5499H28.2552C28.8193 26.5499 29.2765 26.0927 29.2765 25.5286V8.85118H30.9787C31.5428 8.85118 32 8.39394 32 7.82991V1.02139C32 0.45735 31.5428 0.000108004 30.9787 0.000108004H1.02126ZM28.3397 6.80863C28.3119 6.80635 28.2837 6.80519 28.2552 6.80519C28.2268 6.80519 28.1986 6.80635 28.1708 6.80863H3.82903C3.80118 6.80635 3.77301 6.80519 3.74458 6.80519C3.71614 6.80519 3.68797 6.80635 3.66013 6.80863H2.04254V2.04266H29.9575V6.80863H28.3397ZM4.76585 8.85118H27.234V24.5073H4.76585V8.85118ZM9.33157 13.2756C9.33157 12.7116 9.78881 12.2543 10.3529 12.2543H21.6469C22.2109 12.2543 22.6681 12.7116 22.6681 13.2756C22.6681 13.8397 22.2109 14.2969 21.6469 14.2969H10.3529C9.78881 14.2969 9.33157 13.8397 9.33157 13.2756ZM10.3529 17.9013C9.78881 17.9013 9.33157 18.3586 9.33157 18.9226C9.33157 19.4867 9.78881 19.9439 10.3529 19.9439H21.6469C22.2109 19.9439 22.6681 19.4867 22.6681 18.9226C22.6681 18.3586 22.2109 17.9013 21.6469 17.9013H10.3529Z" fill="#F4B740"/>
        </svg>
        <p class="text-[#3C4549] font-poppins pt-[1.188rem] text-[1rem] font-bold leading-5">Are you Sure?</p>
        <p class="pt-1 text text-center">Do you want to archive ? Link will be archived but it will still be functional.</p>
        <div class="border-t border-b py-2 px-2 mt-4 flex justify-center items-center w-full flex-col">
          <div class="flex-1">
            <Checkbox
              id="archive-checkbox"
              v-model="getArchiveStatus.status"
              label="Do not show this message again."
              type="checkbox"
              :isDisabled="false"
              labelClass="text-[#757A8A]"
            ></Checkbox>
          </div>
        </div>
        <div class="pt-10 pb-11 flex gap-x-3 justify-between items-center">
          <Button
            id="login-button"
            type="button"
            class="text-white bg-[#F4B740]"
            buttonClass="btn-lg"
            @click.prevent="archive()"
          >
            <template v-if="!loader" v-slot:label>Archive</template>
            <template v-if="loader" v-slot:loader>
              <Loader></Loader>
            </template>
          </Button>
          <Button
            id="login-button"
            type="button"
            class="border border-gray-500 hover:bg-[#F2F3F8]"
            buttonClass="btn-lg"
            @click="$bvModal.hide('modal-archive-link-v2')"
          >
            <template v-slot:label>Cancel</template>
          </Button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: ['brand'],
  data() {
    return {
      loader: false
    }
  },
  computed: {
    ...mapGetters(['getArchiveStatus'])
  },
  components: {
    Button: () => import('@/ui/ui-kit/v2/Button.vue'),
    Loader: () => import('@/ui/ui-kit/v2/Loader.vue'),
    Checkbox: () => import('@/ui/ui-kit/v2/Checkbox.vue')
  },
  methods: {
    ...mapActions(['archiveLink']),
    async archive() {
      this.loader = true
      await this.archiveLink()
      this.loader = false
      this.$bvModal.hide('modal-archive-link-v2')
    }
  }
}
</script>
<style lang="less">
.delete_brand_model {
  .modal-dialog {
    max-width: 26.5rem !important;
  }
  .modal-content {
    border-radius: 16px !important;
    border-color: #F2F3F8 !important;
    padding: 16px !important;
  }
  .modal-body {
    padding: 0 !important;
  }
}

</style>
